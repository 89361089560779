// store/user-store.ts
import { defineStore } from 'pinia'
import { fetchTeampool } from '~/modules/auth/plugins/fetchTeampool'
import type { JobContext, PartialUser, PartialUserResponse, User, UserResponse } from '~/types'

export const useUserStore = defineStore({
    id: 'user-store',
    state: () => ({
        pending: false,
        pendingRequests: new Set<string>(),
        userList: [] as User[],
        currentJobContext: null as JobContext | null,
        currentBranch: null as String | null,
        jobContextDisabled: false as Boolean,
        jobContextHidden: false as Boolean,
        permissions: [] as string[] | [],
        partialUsers: [] as PartialUser[],
    }),
    actions: {
        async fetchUsers() {
            if (this.userList.length > 0) return // Avoid fetching if already loaded
            const requestUrl = `/users`

            if (this.pendingRequests.has(requestUrl)) {
                return
            }

            this.pendingRequests.add(requestUrl)

            this.pending = true
            const headers = useRequestHeaders(['cookie']) as HeadersInit
            try {
                const users: UserResponse = await fetchTeampool<UserResponse>(requestUrl, { headers })
                this.userList = Array.isArray(users.data) ? users.data : [users.data]
                this.userList = this.userList.filter((user) => user.attributes.isActive && user.attributes.canLoginJobPortal)
            } catch (error: any) {
                throw error
            } finally {
                this.pendingRequests.delete(requestUrl)
                this.pending = false
            }
        },

        async fetchPartialUsers(searchString?: string) {
            const requestUrl = searchString ? `/users-partial?${searchString}` : '/users-partial'

            if (this.pendingRequests.has(requestUrl)) {
                console.log('Request already in progress for:', requestUrl)
                return
            }

            this.pendingRequests.add(requestUrl)

            this.pending = true
            const headers = useRequestHeaders(['cookie']) as HeadersInit
            try {
                const partialUsersResponse: PartialUserResponse = await fetchTeampool<PartialUserResponse>(requestUrl, { headers })
                this.partialUsers = partialUsersResponse.data
            } catch (error: any) {
                throw error
            } finally {
                this.pendingRequests.delete(requestUrl)
                this.pending = false
            }
        },

        async whoami() {
            const authStore = useAuthStore()
            const requestUrl = `/user/whoami`

            if (this.pendingRequests.has(requestUrl)) {
                console.log('Request already in progress for:', requestUrl)
                return
            }

            this.pendingRequests.add(requestUrl)

            this.pending = true
            try {
                const response: UserResponse = await fetchTeampool(requestUrl)
                if (response.data) {
                    // SET USER IN AUTH STORE
                    authStore.setUser(response.data)
                    if (!Array.isArray(response.data)) {
                        if (!this.currentJobContext && response.data.meta && response.data.meta.jobContexts[0]) {
                            this.currentJobContext = response.data.meta.jobContexts[0]
                        }
                        if (!this.currentBranch && response.data.attributes && response.data.attributes.branchId) {
                            this.currentBranch = response.data.attributes.branchId.toString()
                        }
                        if (response.data.meta && response.data.meta.authorisation) {
                            if (this.permissions !== response.data.meta.authorisation) {
                                this.permissions = response.data.meta.authorisation
                            }
                        }
                    } else {
                        response.data.forEach((user) => {
                            console.log(response)
                            if (!this.currentJobContext && user.meta && user.meta.jobContexts[0]) {
                                this.currentJobContext = user.meta.jobContexts[0]
                            }
                            if (!this.currentBranch && user.attributes && user.attributes.branchId) {
                                this.currentBranch = user.attributes.branchId.toString()
                            }
                            if (!this.permissions && response.meta && response.meta.authorisation) {
                                this.permissions = response.meta.authorisation
                            }
                        })
                    }
                }
                return response.data
            } catch (error: any) {
                throw error
            } finally {
                this.pendingRequests.delete(requestUrl)
                this.pending = false
            }
        },

        async setPassword(email: string, payload: any) {
            if (!email) throw new Error('setPassword requires a valid email')
            try {
                const headers = useRequestHeaders(['cookie']) as HeadersInit
                return await fetchTeampool(`/user/password/${email}`, {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers,
                })
            } catch (error) {
                throw error
            }
        },

        async initializeStore() {
            this.currentJobContext = null
            this.currentBranch = null
            await this.whoami()
        },

        setCurrentJobContext(jobContextString: JobContext) {
            if (!jobContextString) throw new Error('setCurrentJobContext requires a valid jobContext')
            this.currentJobContext = jobContextString
        },
        setCurrentBranch(branchId: string | number) {
            if (!branchId) throw new Error('setCurrentBranch requires a number')
            this.currentBranch = branchId.toString()
        },
        setJobContextDisabled(disabled: boolean) {
            this.jobContextDisabled = disabled
        },
        setJobContextHidden(hidden: boolean) {
            this.jobContextHidden = hidden
        },
    },
    getters: {
        getUserList: (state) => state.userList,
        getPermissions: (state) => state.permissions,
        getUserById: (state) => {
            return (userId: number) => state.userList.find((user) => user.attributes.id == userId)
        },
        getPartialUsers: (state) => state.partialUsers,
    },
})
